import React, { useState } from 'react';
import {Typography, Box, Grid, Pagination, Button, Link, Input, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Paper, TableSortLabel,
    Modal, MenuItem, Select,IconButton, List, ListItem, ListItemText
} from '@mui/material';
import GenericLoader from 'components/loaders/generic'
// import WhiteTextField from 'components/form-utilities/white-text-field'
import { Close } from '@mui/icons-material';

import {
  startOfWeek,
  addDays,
  subDays,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  format,
  startOfDay, endOfDay
} from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 980,
  bgcolor: '#f5f5f5',
  boxShadow: 24,
  p: 4,
};

const UserLoginSessions = () => {
    const { http } = global.services

	const itemsPerPage = 50;
	const [total, setTotal] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const [displayed, setDisplayed] = React.useState(itemsPerPage);
	const [noOfPages, setNoOfPages] = React.useState(
		Math.ceil(total / itemsPerPage)
	);
	const [name, setName] = React.useState('');
	const [foundRows, setFoundRows] = React.useState([]);
  // const [oldRows, setOldRows] = React.useState([]);
  const [searchFilter, setSearchFilter ] = useState({
    username:'',
    email:'',
    organization:''
  });
  // const [selectedDate, setSelectedDate] = useState(new Date()); 

  const [loading, setLoading] = React.useState(false);
  const [sortBy, setSortBy] = React.useState('username');
  const [showDateTime, setDateTime] = useState(false);


    React.useEffect(() => {
        loadData()
    }, [page]) // Sort and 
    
    const loadData = async () => {
        setLoading(true);
        try{
        const { data } = await http.get(`admin/loginSession/log?page=${page}&limit=${itemsPerPage}&username=${searchFilter.username}&email=${searchFilter.email}&organization=${searchFilter.organization}`);
        const rows = data.data || [];
        const count = data?.totalCount || rows.length;
        setFoundRows(rows);
        setTotal(count);
        setDisplayed(((page - 1) * itemsPerPage) + rows.length);
        setNoOfPages(Math.ceil(count / itemsPerPage))
        setLoading(false)
        } catch(err){
            setLoading(false)
            console.log(err);
            return;
        }
    }

    const emptyLoad = async () =>{
      setLoading(true);
        try{ 
        const { data } = await http.get(`admin/loginSession/log?page=${page}&limit=${itemsPerPage}`);
        const rows = data.data || [];
        const count = data?.totalCount || rows.length;
        setFoundRows(rows);
        setTotal(count);
        setDisplayed(((page - 1) * itemsPerPage) + rows.length);
        setNoOfPages(Math.ceil(count / itemsPerPage))
        setLoading(false)
        } catch(err){
            setLoading(false)
            console.log(err);
            return;
        }
    }

    const organizationIdLoad = async () =>{
      setLoading(true);
        try{ 
        const { data } = await http.get(`admin/loginSession/log?organization=${searchFilter.organization}`);
        const rows = data.data || [];
        const count = data?.totalCount || rows.length;
        setFoundRows(rows);
        setTotal(count);
        setDisplayed(((page - 1) * itemsPerPage) + rows.length);
        setNoOfPages(Math.ceil(count / itemsPerPage))
        setLoading(false)
        } catch(err){
            setLoading(false)
            console.log(err);
            return;
        }
    }
	
	const handleChange = (event, value) => {
    // console.log(value);
		setPage(value);
		// setDisplayed(value * itemsPerPage);
	};
	const filter = (e) => {
	    const keyword = e.target.value;
      let obj = {
        username:'',
        email:'',
        organization:'',
      };
      if(keyword.trim().length <= 0){
        setSearchFilter({
          username:'',
          email:'',
          organization:'',
        });
        setName('');
        emptyLoad();
        return;
      }
      if (sortBy === 'email') {
        obj = setSearchBy('email', keyword);
      }else if (sortBy === 'organization'){
        obj = setSearchBy('organization', keyword);
      } 
      else {
        obj = setSearchBy('username', keyword); 
      }
     
      setSearchFilter({...obj});
	    setName(keyword);
	  };
  
    const setSearchBy = (current_key, keywork)=>{
      let searches = {...searchFilter} 
     Object.entries(searches).forEach(([key,value])=>{
        if(key === current_key){
          if('organization' === key){
            let datas = [...foundRows];
            datas = datas.filter((data)=>data?.organizationId?.Account_Name.toLowerCase().includes(keywork.toLowerCase()))  
            
            if(datas){
              return searches[key] = datas[0]?.organizationId?._id || '670397f72334530cb33d2e10';
            }else{
              return searches[key] = '670397f72334530cb33d2e10';
            }
          }
          return searches[key] = keywork;
        }else{
            return searches[key] = '';
        }
      });
      // console.log(searches);
      return searches;
    }

    const usTimeBase = (timeDate)=>{
      const date = new Date(timeDate); 
      const estTime = date.toLocaleString('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // 12-hour format
      });

      // console.log('US Eastern Time:', estTime);
      return estTime;
    }

    const handleSearch = (e) => { 
      try { 
        if (e.key === 'Enter') {
          if(sortBy === 'organization'){
            organizationIdLoad();
            return;
          }else{ 
            loadData();
          }
        }
      } catch (error) {
        console.error('Error searching:', error);
      }
    };
    const handleSortBy = event => {
      if(event.target.value === 'date_time'){
        setDateTime(true)
      }
      setSortBy(event.target.value);
    };

    const submitDateTime = async(startTime,endTime)=>{
      try{
        const { data } = await http.get(`admin/loginSession/log?page=${page}&startTime=${startTime}&endTime=${endTime}`);
        const rows = data.data || [];
        const count = data?.totalCount || rows.length;
        setFoundRows(rows);
        setTotal(count);
        setDisplayed(((page - 1) * itemsPerPage) + rows.length);
        setNoOfPages(Math.ceil(count / itemsPerPage))
        setLoading(false);
        setDateTime(false);
        } catch(err){
            setLoading(false)
            console.log(err);
            return;
        }
    }
	return (
  <Box className="orders-container admin_content-wrapper">
   <div>
    <Typography
     component="h1"
     fontSize="30px"
     fontWeight="700"
     marginBottom="30px"
    >
     User Login Sessions
    </Typography>
   </div>

   <Stack
    spacing={5}
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    paddingRight="30px"
   >
    <Box className="flex" alignItems="center">
    </Box>
    <Stack
    spacing={1}
    direction="row"
    justifyContent="end"
    // alignItems="center" 
    > 
    <Box className="flex" alignItems="center">
      <Select
        value={sortBy}
        onChange={handleSortBy}
        sx={{ maxHeight: "40px", minHeight: "unset", background: "#fff", fontSize: '14px' }}
      >
        <MenuItem value="username">Search by Username</MenuItem>
        <MenuItem value="email">Search by Email</MenuItem>
        <MenuItem value="organization">Search by Organization</MenuItem>
        <MenuItem value="date_time" onClick={()=>setDateTime(true)}>Search by Date</MenuItem>
      </Select>
    </Box>
     <div className='relative'> 
     <Input
			  type="search"
        placeholder="Hit Enter To Search"
        value={name}
        onChange={filter} 
        onKeyPress={handleSearch}
        className="input search-list"
		/>
     </div>
    </Stack>
    
   </Stack> 
   {
    loading ? <Box><GenericLoader /></Box>
    : <div>
    <TableContainer component={Paper}>
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ backgroundColor: "#eaebeb" }}>
       <TableRow>
        <TableCell>Username</TableCell>
        <TableCell>Organization</TableCell>
        <TableCell>Email </TableCell>
        <TableCell>Login session time</TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
      {foundRows
        .map((row) => (
         <TableRow
          key={row._id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          hover
          className=""
         >
          <TableCell component="th" scope="row">
            {row.username}
          </TableCell>
          <TableCell  className="cursor-pointer">{row.organizationId?.Account_Name}</TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell component="th" scope="row">
            {/* {dayjs(row.loginTime).format('MM/DD/YYYY')} */}
            {/* {new Date(row.loginTime).toLocaleString()} */}
            {usTimeBase((row.loginTime))}
          </TableCell>
         </TableRow>
        ))} 
      </TableBody>
     </Table>
    </TableContainer>
    <Grid container justifyContent="space-between" marginTop="30px">
     <Grid item>
      {displayed <= foundRows.length ? (
       <Typography>
        {displayed} out of {total}
       </Typography>
      ) : (
       <Typography>
        {foundRows.length} out of {total}
       </Typography>
      )}
     </Grid>
     <Grid item>
      <Pagination
       count={noOfPages}
       page={page}
       onChange={handleChange}
       defaultPage={1}
       color="primary"
       size="large"
       className="pagination"
      />
     </Grid>
    </Grid>
   </div>
   }
   <DateTimeModal item={showDateTime} onClose={() => setDateTime(false)} filter={filter} submitDateTime={submitDateTime}/>
  </Box>
 );

}

function DateTimeModal ({ item, onClose, submitDateTime}) {
  const today = new Date();
  const start = startOfDay(today);
  const end = endOfDay(today);
  const [selectedRange, setSelectedRange] = useState({ from: start, to: end });

  const handleSearch = async() => {
    // Convert the start and end date-times to JavaScript Date objects
    let start = new Date(selectedRange.from).toISOString();
    let end = new Date(selectedRange.to).toISOString();
    submitDateTime(start ,end)
  };

      // Handle selecting different ranges
      const selectToday = () => {
        const today = new Date();
        const start = startOfDay(today);
        const end = endOfDay(today);
        setSelectedRange({ from: start, to: end });
      };

      const selectYesterday = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const start = startOfDay(yesterday);
        const end = endOfDay(yesterday);
        setSelectedRange({ from: start, to: end });
      };

      const selectThisWeek = () => {
        const today = new Date();
        const start = startOfWeek(today, { weekStartsOn: 1 });
        const end = addDays(start, 6);
        setSelectedRange({ from: start, to: end });
      };

      const selectLastWeek = () => {
        const today = new Date();
        const start = startOfWeek(subDays(today, 7), { weekStartsOn: 1 });
        const end = addDays(start, 6);
        setSelectedRange({ from: start, to: end });
      };

      const selectPastTwoWeeks = () => {
        const today = new Date();
        const start = subDays(today, 14);
        const end = today;
        setSelectedRange({ from: start, to: end });
      };

      const selectThisMonth = () => {
        const today = new Date();
        const start = startOfMonth(today);
        const end = endOfMonth(today);
        setSelectedRange({ from: start, to: end });
      };

      const selectLastMonth = () => {
        const today = new Date();
        const start = startOfMonth(subDays(today, 30));
        const end = endOfMonth(subDays(today, 30));
        setSelectedRange({ from: start, to: end });
      };

      const selectThisYear = () => {
        const start = startOfYear(new Date());
        const end = endOfYear(new Date());
        setSelectedRange({ from: start, to: end });
      };

      const selectLastYear = () => {
        const start = startOfYear(subDays(new Date(), 365));
        const end = endOfYear(subDays(new Date(), 365));
        setSelectedRange({ from: start, to: end });
      };

      return (
        <Modal
            open={item}
        	  onClose={onClose}
        	  aria-labelledby="calendar-modal-title"
            aria-describedby="calendar-modal-description"
        > 
          <Box sx={style}>
            <Typography id="role-form"
                variant="h6"
                component="span"
                fontWeight="600"
                display="block"
                className="text-center mt-6"
              >
                <IconButton
                  aria-label="close"
                  onClick={()=>onClose()}
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <Close />
                </IconButton>
                  Search by Date
              </Typography>
              <Box display="flex" >
              {/* Sidebar */}
              <Box
                sx={{
                  width: '200px',
                  bgcolor: 'primary.main',
                  color: 'white',
                  padding: '20px',
                }}
              >
                <List>
                  <ListItem button onClick={selectToday}>
                    <ListItemText primary="Today" className='text-white'/>
                  </ListItem>
                  <ListItem button onClick={selectYesterday}>
                    <ListItemText primary="Yesterday" className='text-white' />
                  </ListItem>
                  <ListItem button onClick={selectThisWeek}>
                    <ListItemText primary="This week" className='text-white' />
                  </ListItem>
                  <ListItem button onClick={selectLastWeek}>
                    <ListItemText primary="Last week" className='text-white' />
                  </ListItem>
                  <ListItem button onClick={selectPastTwoWeeks}>
                    <ListItemText primary="Past two weeks" className='text-white'/>
                  </ListItem>
                  <ListItem button onClick={selectThisMonth}>
                    <ListItemText primary="This month" className='text-white'/>
                  </ListItem>
                  <ListItem button onClick={selectLastMonth} className='text-white'>
                    <ListItemText primary="Last month" />
                  </ListItem>
                  <ListItem button onClick={selectThisYear}>
                    <ListItemText primary="This year" className='text-white' />
                  </ListItem>
                  <ListItem button onClick={selectLastYear}>
                    <ListItemText primary="Last year" className='text-white'/>
                  </ListItem>
                </List>
              </Box>

              {/* Calendar */}
              <Box flexGrow={1} padding="20px">
                <DayPicker
                  mode="range"
                  selected={selectedRange}
                  // onSelect={setSelectedRange}
                  onSelect={(range) => {
                    if (range?.from && range?.to) {
                      setSelectedRange(range);
                    }
                  }}
                  showOutsideDays
                  numberOfMonths={2}
                />
              {selectedRange.from && selectedRange.to && (
                  <p>
                    Selected range: {format(selectedRange.from, 'PPP')} to {format(selectedRange.to, 'PPP')}
                  </p>
                )}
              <Box className="flex flex-col">
                <Button
                    variant="contained"
                    type="submit"
                    color="error"
                    className="normal-case rounded-none"
                    sx={{ margin: "15px 0"}}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                  <Button
                    variant="text"
                    onClick={()=>onClose()}
                    className="normal-case rounded-none block text-center"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            
            </Box> 
          </Box>
        
       </Modal>
      );

} 


export default UserLoginSessions
